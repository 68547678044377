import React, { lazy, Suspense, useEffect } from 'react';


import { LoadingScreen } from './shared/components/layout/LoadingScreen/LoadingScreen';

const AppMain = lazy(() => import('./AppMain'));

export const App = () => {

  useEffect(() => {
    const loading = document.querySelector('#ih-loading');
    if(loading) {
      loading.remove();
    }
  }, []);

  return (
    <Suspense fallback={<LoadingScreen/>}>
      <AppMain/>
    </Suspense>
  );
}

export default App;
