import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    minWidth: '80vw',
    height: 'calc(100vh - 80px)',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 4,
  },
  icon: {
    height: 75,
    width: 75,
    animationName: 'loadingSpin',
    animationDuration: '1500ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
  icon2: {
    height: 45,
    width: 45,
    marginLeft: -60,
    animationName: 'loadingSpin',
    animationDuration: '2500ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
  },
}));

export const LoadingScreen = () => {

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img
        src={`${process.env.REACT_APP_IMAGES_STORAGE}/ih-rainbow-circled-border.svg`}
        className={classes.icon}
        loading="lazy"
        alt="Loading"
      />
      <img
        src={`${process.env.REACT_APP_IMAGES_STORAGE}/ih-rainbow-circled-border.svg`}
        className={classes.icon2}
        loading="lazy"
        alt="Loading"
      />
    </div>
  )
}
